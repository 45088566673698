import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { ErrorMessage, Field } from "formik";
import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { cropType, speciesType } from "../../config/fieldIntialValues";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";

const CropFormComponent = (props) => {
  const { t } = useTranslation();
  const { values, setFieldValue, handleChange } = props?.formikProps;
  const [type, setType] = React.useState(values?.crop?.type);

  const setDate = (date) => {
    const value = dayjs(date).format("DD/MM/YYYY");
    return value;
  };

  const handleSelectChange = (event) => {
    const newValue = event.target.value;
    if (newValue !== type) {
      setType(newValue);
      setFieldValue("crop.planting_date", null);
      setFieldValue("crop.planting_year", null);
      setFieldValue("crop.type", newValue);
    }
  };

  return (
    <Fragment>
      <div>
        <h4>{t("Crop")}</h4>
      </div>

      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={6}>
          <FormControl fullWidth>
            <InputLabel id="type-select-label">
              {t("crop_form.input_label_type")}
            </InputLabel>
            <Select
              label={t("crop_form.select_label_type")}
              id="crop.type"
              name="crop.type"
              labelId="type-select-label"
              value={values?.crop?.type}
              onChange={handleSelectChange}
            >
              <MenuItem value="">
                <em>{t("none")}</em>
              </MenuItem>
              {Object.values(cropType).map((option, i) => (
                <MenuItem key={i} value={option}>
                  {t(option)}
                </MenuItem>
              ))}
            </Select>
            <ErrorMessage name="crop.type" component="div" />
          </FormControl>
        </Grid>
        {type && (
          <Fragment>
            <Grid item xs={12} sm={12} md={6}>
              <FormControl fullWidth>
                <InputLabel id="species-select-label">
                  {t("crop_form.input_label_species")}
                </InputLabel>
                <Select
                  label={t("crop_form.select_label_species")}
                  id="crop.species"
                  name="crop.species"
                  labelId="species-select-label"
                  value={values?.crop?.species}
                  onChange={handleChange}
                >
                  <MenuItem value="">
                    <em>{t("none")}</em>
                  </MenuItem>
                  {Object.values(speciesType).map((option, i) => (
                    <MenuItem key={i} value={option}>
                      {t(option)}
                    </MenuItem>
                  ))}
                </Select>
                <ErrorMessage name="crop.species" component="div" />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <Field
                as={TextField}
                label={t("crop_form.label_variety_name")}
                name="crop.variety_name"
                variant="outlined"
                fullWidth
              />
              <ErrorMessage name="crop.variety_name" component="div" />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <Field
                as={TextField}
                label={t("crop_form.label_variety_code")}
                name="crop.variety_code"
                variant="outlined"
                fullWidth
              />
              <ErrorMessage name="crop.variety_code" component="div" />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <Field
                as={TextField}
                label={t("crop_form.label_spacing_lanes")}
                name="crop.distance_line"
                variant="outlined"
                fullWidth
              />
              <ErrorMessage name="crop.distance_line" component="div" />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <Field
                as={TextField}
                label={t("crop_form.label_spacing_individuals")}
                name="crop.distance_individuals"
                variant="outlined"
                fullWidth
              />
              <ErrorMessage name="crop.distance_individuals" component="div" />
            </Grid>
            {cropType.annual === type && (
              <Grid item xs={12} sm={12} md={6}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    format="DD/MM/YYYY"
                    style={{ width: "100%" }}
                    label={t("crop_form.plating_date")}
                    name="crop.planting_date"
                    onChange={(date) => {
                      setFieldValue("crop.planting_date", setDate(date));
                    }}
                    renderInput={(params) => (
                      <TextField {...params} variant="outlined" fullWidth />
                    )}
                  />
                </LocalizationProvider>
                <ErrorMessage name="crop.planting_date" component="div" />
              </Grid>
            )}
            {cropType.perennial === type && (
              <Grid item xs={12} sm={12} md={6}>
                <Field
                  as={TextField}
                  label={t("crop_form.field_year_plantation")}
                  name="crop.planting_year"
                  variant="outlined"
                  fullWidth
                  inputProps={{ min: 2015, step: 1 }}
                  type="number"
                />
                <ErrorMessage name="crop.planting_year" component="div" />
              </Grid>
            )}
            
            <Grid item xs={12} sm={12} md={6}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  format="DD/MM/YYYY"
                  style={{ width: "100%" }}
                  label={t("crop_form.date_flowering")}
                  name="crop.flowering_date"
                  onChange={(date) => {
                    setFieldValue("crop.flowering_date", setDate(date));
                  }}
                  renderInput={(params) => (
                    <TextField {...params} variant="outlined" fullWidth />
                  )}
                />
              </LocalizationProvider>
              <ErrorMessage name="crop.flowering_date" component="div" />
            </Grid>            
            <Grid item xs={12} sm={12} md={6}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  format="DD/MM/YYYY"
                  style={{ width: "100%" }}
                  label={t("crop_form.date_ripening")}
                  name="crop.rieping_date"
                  onChange={(date) => {
                    setFieldValue("crop.rieping_date", setDate(date));
                  }}
                  renderInput={(params) => (
                    <TextField {...params} variant="outlined" fullWidth />
                  )}
                />
              </LocalizationProvider>
              <ErrorMessage name="crop.rieping_date" component="div" />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  format="DD/MM/YYYY"
                  style={{ width: "100%" }}
                  label={t("crop_form.harvest_date")}
                  name="crop.harvest_date"
                  onChange={(date) => {
                    setFieldValue("crop.harvest_date", setDate(date));
                  }}
                  renderInput={(params) => (
                    <TextField {...params} variant="outlined" fullWidth />
                  )}
                />
              </LocalizationProvider>
              <ErrorMessage name="crop.harvest_date" component="div" />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <Field
                as={TextField}
                label={t("crop_form.crop_yelds_label")}
                name="crop.crop_yelds"
                variant="outlined"
                fullWidth
              />
              <ErrorMessage name="crop.crop_yelds" component="div" />
            </Grid>
          </Fragment>
        )}
      </Grid>
    </Fragment>
  );
};

export default CropFormComponent;
